import { Controller } from "@hotwired/stimulus";
class src_default extends Controller {
  connect() {
    this.hidden = this.inputTarget.type === "password";
    this.class = this.hasHiddenClass ? this.hiddenClass : "hidden";
  }
  toggle(e) {
    e.preventDefault();
    this.inputTarget.type = this.hidden ? "text" : "password";
    this.hidden = !this.hidden;
    this.iconTargets.forEach((icon) => icon.classList.toggle(this.class));
  }
}
src_default.targets = ["input", "icon"];
src_default.classes = ["hidden"];
export { src_default as default };
